import router from '@/router';
import api from './api';
const timeService = {
    listarTodos() {
      return api.get("/times").then(response => response.data)
      .catch(error => {
        if (error.response && error.response.status === 403){
          router.push("/login")
        }
        throw error;
      });
    },
    buscarPorId(id) {
      return api.get(`/times/${id}`)
          .then(response => response.data)
          .catch(error => {
            console.error(`Erro ao buscar campo com ID ${id}:`, error);
            throw error;
          });
    },
    salvar(time) {
      return api.post("/times", time);
    },
    atualizar(id, time) {
      return api.put(`/times/${id}`, time);
    },
    deletarPorId(id) {
      return api.delete(`/times/${id}`);
    }}
    export default timeService