<template>
  <div>
    <el-table :data="tableData" stripe>
      <el-table-column prop="nome" label="Nome"></el-table-column>
      <el-table-column prop="cidade" label="Cidade"></el-table-column>
      <el-table-column prop="dono" label="Dono"></el-table-column>
      <el-table-column prop="cpfCnpj" label="Cpf/Cnpj"></el-table-column>
      <el-table-column align="right">
        <template slot-scope="scope">
          <el-button @click="editItem(scope.row)" size="mini" type="primary"
            ><i class="el-icon-edit"></i
          ></el-button>
          <el-button
            @click="visualizaCampo(scope.row)"
            size="mini"
            type="primary"
            ><i class="el-icon-view"></i
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-button
      class="add-button"
      @click="addItem"
      type="primary"
      icon="el-icon-plus"
    ></el-button>
    <el-dialog
      :visible.sync="dialogVisible"
      title="Detalhes do Campo"
      width="90%"
    >
      <el-form label-position="top" :model="location" class="location-form">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Nome">
              <el-input
                v-model="location.nome"
                placeholder="Nome"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Rua">
              <el-input
                v-model="location.rua"
                placeholder="Rua"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Bairro">
              <el-input
                v-model="location.bairro"
                placeholder="Bairro"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Cidade">
              <el-input
                v-model="location.cidade"
                placeholder="Cidade"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Estado">
              <el-input
                v-model="location.estado"
                placeholder="Estado"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="País">
              <el-input
                v-model="location.pais"
                placeholder="País"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Dono">
              <el-input
                v-model="location.dono"
                placeholder="Dono"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Data de Criação">
              <el-input
                v-model="location.dataCriacao"
                placeholder="Data de Criação"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Hora de Criação">
              <el-input
                v-model="location.horaCriacao"
                placeholder="Hora de Criação"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Cpf/Cnpj">
              <el-input
                v-model="location.cpfCnpj"
                placeholder="Cpf ou Cnpj"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24">
            <el-form-item label="Foto">
              <img
                :src="location.foto"
                alt="Foto do Local"
                style="max-width: 100%; height: auto"
                v-if="location.foto"
              />
              <span v-else>Sem foto</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Fechar</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import campoServices from '@/services/campoService'

export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      location: {},
    }
  },
  async mounted() {
    this.tableData = await campoServices.listarTodos()
  },
  methods: {
    addItem() {
      this.$router.push('/novoCampo')
    },
    editItem(item) {
      this.$router.push('/editCampo/' + item.id)
    },
    deleteItem(item) {
      console.log('Excluir item', item)
    },
    visualizaCampo(item) {
      this.location = item
      this.dialogVisible = true
    },
  },
}
</script>

<style scoped>
.add-button {
  position: fixed;
  bottom: 20px;
  padding: 15px;
  right: 20px;
  border-radius: 50%;
}
</style>
