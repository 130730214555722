<template>
  <div class="cards-container">
    <el-row>
      <h1>Minhas Ultimas Partidas</h1>
      <el-table :data="partidas" border style="width: 100%">
        <el-table-column prop="n1" label="Time 1" align="center"></el-table-column>
        <el-table-column prop="n2" label="Time 2" align="center"></el-table-column>
        <el-table-column 
          prop="data" 
          label="Data da Partida" 
          align="center" 
          :formatter="formatarData">
        </el-table-column>
        <el-table-column label="Ações" align="center" width="150">
          <template #default="scope">
            <el-button 
              type="primary" 
              size="mini" 
              @click="irParaDadosPartida(scope.row.id)">
              Detalhes
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import partidaServices from '@/services/partidaService';

export default {
  name: 'homeView',
  data() {
    return {
      partidas: [],
    };
  },
  methods: {
    formatarData(row, column, cellValue) {
      if (!cellValue) return '';
      const [year, month, day] = cellValue.split('-');
      return `${day}/${month}/${year}`;
    },
    irParaDadosPartida(id) {
      this.$router.push(`/dadosPartida/${id}`);
    },
  },
  mounted() {
    partidaServices.ultimaspartidas().then((p) => {
      this.partidas = p;
    });
  },
};
</script>

<style scoped>
.cards-container {
  padding: 20px;
}

.el-button {
  text-transform: uppercase;
}

a {
  text-decoration: none;
  color: #000;
}
</style>
