import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import locale from 'element-ui/lib/locale/lang/pt';
import { VueMaskDirective } from 'v-mask';
Vue.config.productionTip = false
Vue.use(ElementUI, { locale });
Vue.directive('mask', VueMaskDirective);

new Vue({
  router,
  el: '#app',
  render: h => h(App)
}).$mount('#app')
