<template>
  <div class="tela">
    <div class="time-info">
      <h3>{{ time ? time.nome : ''}}</h3>
      <ul class="jogadores-lista">
        <li v-for="jogador in jogadores" :key="jogador.usuario.id">
          <span class="jogador-nome">{{ jogador.usuario.nome }}</span> 
          <span class="jogador-posicao"> - {{ jogador.posicao }}</span>
        </li>
      </ul>
    </div>

    <el-form :model="time" :rules="rules" ref="timeForm" label-width="0">
      <div class="form-row">
        <div class="container-infor-jogador">
          <span>Busque jogadores pelo nome, adicione ou remova do time</span>
        </div>
        <el-form-item class="search-input">
          <span>Buscar Jogador</span>
          <el-input v-model="searchTerm" @input="buscarJogadores" placeholder="Digite o nome do jogador"></el-input>
        </el-form-item>
        <el-table :data="jogadoresFiltrados" style="width: 100%" class="jogadores-tabela">
          <el-table-column prop="nome" label="Nome"></el-table-column>
          <el-table-column label="Ações">
            <template v-slot="scope">
              <el-button size="mini" type="primary" @click="openJogadorModal(scope.row)">
                {{ jogadorEstaSelecionado(scope.row.id) ? 'Remover' : 'Adicionar' }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-form>

    <el-dialog title="Adicionar Jogador" :visible.sync="modalVisible" width="90%" :close-on-click-modal="false">
      <el-form :model="jogadorSelecionado" label-width="100px">
        <el-form-item label="Posição">
          <el-select v-model="jogadorSelecionado.posicao" placeholder="Selecione a posição" style="width: 100%">
            <el-option label="Goleiro" value="goleiro"></el-option>
            <el-option label="Defesa" value="defesa"></el-option>
            <el-option label="Meio-campo" value="meio-campo"></el-option>
            <el-option label="Atacante" value="atacante"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelar">Cancelar</el-button>
        <el-button type="primary" @click="adicionarJogador">Adicionar</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import timeService from '@/services/timeService';
import jogadorService from '@/services/jogadorService';

export default {
  data() {
    return {
      id: null,
      jogadores: [],
      searchTerm: '',
      jogadoresFiltrados: [],
      modalVisible: false,  // Controla a visibilidade do modal
      jogadorSelecionado: { id: '', nome: '', posicao: '' }, // Dados do jogador a ser adicionado
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.time = await timeService.buscarPorId(this.id);
      this.jogadores = await jogadorService.buscarPorId(this.id);
    }
  },
  methods: {
    async buscarJogadores() {
      if (this.searchTerm) {
        try {
          const jogadores = await jogadorService.buscarJogador(this.searchTerm);
          this.jogadoresFiltrados = jogadores;
        } catch (error) {
          console.error('Erro ao buscar jogadores:', error);
        }
      } else {
        this.jogadoresFiltrados = [];
      }
    },
    jogadorEstaSelecionado(jogadorId) {
      return this.jogadores.some(jogador => jogador.usuario.id === jogadorId);
    },
    async openJogadorModal(jogador) {
      if (this.jogadorEstaSelecionado(jogador.id)) {
        // Se o jogador já estiver no time, remova-o
        const index = this.jogadores.findIndex(j => j.usuario.id === jogador.id);
        await jogadorService.removerJogador(this.id, jogador.id);
        this.jogadores.splice(index, 1);
        this.jogadores = await jogadorService.buscarPorId(this.id);
      } else {
        // Abre o modal para adicionar o jogador
        this.jogadorSelecionado = { ...jogador, posicao: '' };
        this.modalVisible = true;
      }
    },
    adicionarJogador() {
      if (this.jogadores.length === 6) {
        this.$message.error('O time já possui 5 jogadores');
        return;
      }
      const { id, nome, posicao } = this.jogadorSelecionado;
      if (!posicao) {
        this.$message.error('Posição são obrigatórios');
        return;
      }
      jogadorService.adicionarJogador(this.id, id, posicao );
      this.jogadores.push({ time: {id: this.id}, usuario: {id, nome: nome}, posicao });
      this.modalVisible = false;
    },
    cancelar() {
      this.modalVisible = false;
    },
  },
};
</script>

<style scoped>
/* Container principal */
.tela {
  margin: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Estilização das informações do time */
.time-info {
  margin-bottom: 20px;
}

h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.jogadores-lista {
  list-style-type: none;
  padding-left: 0;
}

.jogadores-lista li {
  font-size: 18px;
  margin: 5px 0;
}

.jogador-nome {
  font-weight: bold;
  color: #007bff;
}

.jogador-posicao {
  color: #6c757d;
}

/* Formulário de busca */
.search-input {
  margin-top: 15px;
  width: 100%;
}

.search-input span {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
  display: inline-block;
}

.el-input {
  border-radius: 5px;
  border: 1px solid #ddd;
  transition: border-color 0.3s;
}

.el-input:hover,
.el-input:focus {
  border-color: #007bff;
}

/* Tabela de jogadores */
.jogadores-tabela {
  margin-top: 20px;
}

.el-table th,
.el-table td {
  text-align: center;
}

.el-button {
  transition: background-color 0.3s;
}

.el-button:hover {
  background-color: #0056b3;
}

/* Modal */
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.el-dialog__header {
  text-align: center;
}

/* Ajustes para dispositivos móveis */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .el-dialog__body {
    padding: 10px;
  }

  .search-input {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .dialog-footer {
    flex-direction: column;
    gap: 10px;
  }

  .el-button {
    width: 100%;
  }
}

/* Container com informação adicional */
.container-infor-jogador {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #007bff;
  padding: 15px;
  border-radius: 8px;
  background-color: #e9f5ff;
}

.container-infor-jogador span {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}
</style>