import router from '@/router';
import api from './api';

const campoServices ={
    buscarJogador(nome) {
        return api.get("/jogadores/usuario/buscar", {params: {nome: nome}})
          .then(response => response.data)
          .catch(error => {
            if (error.response && error.response.status === 403){
              router.push("/login")
            }
            console.error('Erro ao listar campos:', error);
            throw error;
          });
      },
    adicionarJogador(idTime, idUsuario, posicao) {
      return api.post("/jogadores/adicionar", {idTime, idUsuario, posicao});
    },
    removerJogador(idTime, idUsuario) {
      return api.post("/jogadores/remove", {idTime, idUsuario});
    },
    buscarPorId (id) {
      return api.get(`/jogadores/buscar/${id}`)
        .then(response => response.data)
        .catch(error => {
          if (error.response && error.response.status === 403){
            router.push("/login")
          }
          console.error('Erro ao buscar jogador:', error);
          throw error;
        });
    }
}
export default campoServices