import router from '@/router';
import api from './api';

const partidaServices ={
    // Lista todas as partidas
  findAll() {
    return api.get("/partidas")
      .then(response => response.data)
      .catch(error => {
        if (error.response && error.response.status === 403){
          router.push("/login")
        }
        console.error('Erro ao listar partidas:', error);
        throw error;
      });
  },
  findById(id) {
    return api.get(`${"/partidas"}/${id}`)
      .then(response => response.data)
      .catch(error => {
        console.error(`Erro ao buscar partida com ID ${id}:`, error);
        throw error;
      });
  },
  save(partida) {
    return api.post("/partidas", partida)
      .then(response => response.data)
      .catch(error => {
        console.error('Erro ao criar partida:', error);
        throw error;
      });
  },
  update(id, partida) {
    return api.put(`${"/partidas"}/${id}`, partida)
      .then(response => response.data)
      .catch(error => {
        console.error(`Erro ao atualizar partida com ID ${id}:`, error);
        throw error;
      });
  },
  deleteById(id) {
    return api.delete(`${"/partidas"}/${id}`)
      .then(response => response.data)
      .catch(error => {
        console.error(`Erro ao deletar partida com ID ${id}:`, error);
        throw error;
      });
  },
  ultimaspartidas(id) {
    return api.get(`${"/partidas/ultimaspartidas"}`)
      .then(response => response.data)
      .catch(error => {
        console.error(`Erro ao buscar partida com ID ${id}:`, error);
        throw error;
      });
  }
}
export default partidaServices