<template>
    <div>
      <el-table :data="tableData" stripe>
        <el-table-column prop="nome" label="Nome"></el-table-column>
        <el-table-column  align="right">
          <template slot-scope="scope">
            <el-button @click="editItem(scope.row)" size="mini" type="primary"><i class="el-icon-edit"></i></el-button>
            <el-button @click="visualizaTime(scope.row)" size="mini" type="primary"><i class="el-icon-view"></i></el-button>
            <el-button @click="adicionarJogador(scope.row)" size="mini" type="primary"><i class="el-icon-user"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button class="add-button" @click="addItem" type="primary" icon="el-icon-plus"></el-button>
      <el-dialog :visible.sync="dialogVisible" title="Detalhes do Time" width="90%">
        <el-form label-position="top" :model="team" class="team-form">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Nome">
                <el-input v-model="team.nome" placeholder="Nome" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Cidade">
                <el-input v-model="team.cidade" placeholder="Cidade" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Estado">
                <el-input v-model="team.estado" placeholder="Estado" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
  
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="País">
                <el-input v-model="team.pais" placeholder="País" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Administrador">
                <el-input v-model="team.admin.nome" placeholder="Administrador" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Média">
                <el-input v-model="team.media" placeholder="Média" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
  
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Data de Criação">
                <el-input v-model="team.dataCriacao" placeholder="Data de Criação" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Hora de Criação">
                <el-input v-model="team.horaCriacao" placeholder="Hora de Criação" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Foto">
                <img :src="team.foto" alt="Foto do Time" style="max-width: 100%; height: auto;" v-if="team.foto"/>
                <span v-else>Sem foto</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
  
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Fechar</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  <script>
import timeService from '@/services/timeService';

  export default {
    data() {
      return {
        tableData: [],
        dialogVisible: false,
        team: {
        nome: "Nome do Time",
        cidade: "Cidade do Time",
        estado: "Estado do Time",
        pais: "País do Time",
        admin: {
          nome: "Administrador"
        },
        foto: null,
        media: "Média do Time",
        dataCriacao: "2024-08-27",
        horaCriacao: "15:00"
      }
      };
    },
    async mounted() {
      this.tableData = await timeService.listarTodos()
    },
    methods: {
      addItem() {
        this.$router.push("/novoTime")
      },
      editItem(item) {
        this.$router.push('/editTime/'+item.id);
      },
      adicionarJogador(item) {
        this.$router.push('/jogadores/'+item.id);
      },
      visualizaTime(item) {
        // Lógica para excluir o item
        this.team= item
        this.team.foto = this.team.foto ? this.team.foto : null
        this.dialogVisible = true
      },
    },
  };
  </script>
  <style scoped>
  .add-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 15px;
    border-radius: 100%;
  }
  </style>