<template>
  <div class="game-stats-container">
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>
      <p>Carregando dados...</p>
    </div>
    <div v-else>
      <div v-if="statsTime1.time" class="game-stats">
        <div
          ref="statsSection"
          class="stats-section"
          :class="['stats-section', { 'share-mode': compartilhando }]"
        >
          <h2>Estatísticas da Partida</h2>
          <!-- Posse de Bola -->
          <div class="stat-time">
            <div class="time-container">
              <div class="container-logo-time">
                <img
                  class="time-foto"
                  :src="statsTime1.time.foto"
                  v-if="statsTime1.time.foto"
                />
                <img class="time-foto" :src="semLogo1" v-else />
              </div>
              <div class="time-nome">
                <span class="value">{{ statsTime1.time.nome }}</span>
              </div>
            </div>
            <div class="time-container">
              <div class="container-logo-time">
                <img
                  class="time-foto"
                  :src="statsTime2.time.foto"
                  v-if="statsTime2.time.foto"
                />
                <img class="time-foto" :src="semLogo2" v-else />
              </div>
              <div class="time-nome">
                <span class="value">{{ statsTime2.time.nome }}</span>
              </div>
            </div>
          </div>

          <div class="stat">
            <span class="value">
              {{ statsTime1.posseDeBola.split('(')[0] }}
              <div class="percentage">
                ({{ statsTime1.posseDeBola.split('(')[1]?.replace(')', '') }})
              </div>
            </span>
            <label>Posse de Bola</label>
            <span class="value">
              {{ statsTime2.posseDeBola.split('(')[0] }}
              <div class="percentage">
                ({{ statsTime2.posseDeBola.split('(')[1]?.replace(')', '') }})
              </div>
            </span>
          </div>

          <!-- Outras Estatísticas -->
          <div
            class="stat"
            v-for="(stat, index) in filteredStatsLabels"
            :key="index"
          >
            <span class="value">{{ statsTime1[stat.key] }}</span>
            <label>{{ stat.label }}</label>
            <span class="value">{{ statsTime2[stat.key] }}</span>
          </div>
          <el-row>
            <el-col :span="24" class="space-betwen" v-show="compartilhando">
              <img :src="logo" alt="logo aite" style="width: 250px" />
            </el-col>
          </el-row>
        </div>

        <el-row>
          <el-col :span="24" class="space-betwen">
            <el-button type="success" icon="el-icon-share" @click="shareStats"
              >Compartilhar Estatísticas</el-button
            >
          </el-col>
        </el-row>

        <div class="stat">
          <el-row>
            <el-col :span="24" v-for="midia in midias" :key="midia.id">
              <img
                class="img-responsive"
                :src="'data:image/png;base64,' + midia.arquivo"
              />
              <div class="media-actions">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-download"
                  @click="downloadMedia(midia)"
                  >Baixar</el-button
                >
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-share"
                  @click="shareMedia(midia)"
                  >Compartilhar</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="cointainer-times">
          <div class="cointainer-time">
            <div class="nome-time">
              {{ statsTime1.time.nome }}
            </div>
            <div
              class="row-dados-jogador-margin"
              v-for="jogador in statsTime1.jogadorPartidaDtos"
              :key="jogador.id"
            >
              <div class="row-dados-jogador">
                <img
                  class="jogador-toto"
                  v-if="jogador.foto"
                  :src="jogador.foto"
                /><img v-else :src="userFoto" class="jogador-toto" />
                <span class="jogador-nome">{{ jogador.nome }}</span>
                <span class="jogador-posicao">{{ jogador.posicao }}</span>
              </div>
              <el-button @click="navegarDadosJogador(jogador.id)" size="mini"
                ><i class="el-icon-data-board"></i
              ></el-button>
            </div>
          </div>
          <div class="cointainer-time">
            <div class="nome-time">
              {{ statsTime2.time.nome }}
            </div>
            <div
              class="row-dados-jogador-margin"
              v-for="jogador in statsTime2.jogadorPartidaDtos"
              :key="jogador.id"
            >
              <div class="row-dados-jogador">
                <img
                  class="jogador-toto"
                  v-if="jogador.foto"
                  :src="jogador.foto"
                /><img v-else :src="userFoto" class="jogador-toto" />
                <span class="jogador-nome">{{ jogador.nome }}</span>
                <span class="jogador-posicao">{{ jogador.posicao }}</span>
              </div>
              <el-button @click="navegarDadosJogador(jogador.id)" size="mini"
                ><i class="el-icon-data-board"></i
              ></el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h2>Dados Não sincronizados</h2>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import relatorioPartidaServices from '@/services/relatorioPartidaServices';
import userFoto from '@/assets/user.png';
import semLogo1 from '@/assets/sem-logo-1.png';
import semLogo2 from '@/assets/sem-logo-2.png';
import logo from '@/assets/logo.png';

export default {
  data() {
    return {
      statsTime1: {},
      userFoto: userFoto,
      semLogo1: semLogo1,
      semLogo2: semLogo2,
      isLoading: true,
      compartilhando: false,
      logo,
      midias: [],
      statsTime2: {},
      statsLabels: [
        { key: 'posseDeBola', label: 'Posse de Bola (%)' },
        { key: 'passesCorretos', label: 'Passes Corretos' },
        { key: 'passesRecebidos', label: 'Passes Recebidos' },
        { key: 'passesErrados', label: 'Passes Errados' },
        { key: 'totalChutes', label: 'Total Chutes' },
        { key: 'chutesAoGol', label: 'Chutes ao Gol' },
        { key: 'bolasRecuperadas', label: 'Bolas Recuperadas' },
        { key: 'defesasGoleiro', label: 'Defesas do Goleiro' },
        { key: 'kmPercorridos', label: 'Km Percorridos' },
        { key: 'assistenciasAGols', label: 'Assistências para Gol' },
        { key: 'gols', label: 'Gols' },
        { key: 'escanteios', label: 'Escanteios' },
        { key: 'score', label: 'Score' },
      ],
    };
  },
  computed: {
    filteredStatsLabels() {
      return this.statsLabels.filter((stat) => stat.key !== 'posseDeBola');
    },
  },
  async mounted() {
    try {
      const dados = await relatorioPartidaServices.findByIdPartida(
        this.$route.params.id
      );
      this.statsTime1 = dados[0];
      this.statsTime2 = dados[1];
      this.midias = await relatorioPartidaServices.findMidiasByIdPartida(
        this.$route.params.id
      );
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    } finally {
      this.isLoading = false; // Desativa o carregamento
    }
  },
  methods: {
    navegarDadosJogador(idJogador) {
      this.$router.push(
        '/dadosJogador/' + idJogador + '/' + this.$route.params.id
      );
    },
    isImage(nomeArquivo) {
      const imageExtensions = ['png', 'jpg', 'jpeg', 'gif'];
      const fileExtension = nomeArquivo.split('.').pop().toLowerCase();
      return imageExtensions.includes(fileExtension);
    },
    downloadMedia(media) {
      const link = document.createElement('a');
      const mimeType = this.isImage(media.nomeArquivo)
        ? 'image/png'
        : 'video/mp4';
      link.href = `data:${mimeType};base64,${media.arquivo}`;
      link.download = media.nomeArquivo;
      link.click();
    },
    async shareMedia(media) {
      if (navigator.share) {
        const mimeType = this.isImage(media.nomeArquivo)
          ? 'image/png'
          : 'video/mp4';
        const blob = await fetch(
          `data:${mimeType};base64,${media.arquivo}`
        ).then((res) => res.blob());
        const file = new File([blob], media.nomeArquivo, { type: mimeType });

        try {
          await navigator.share({
            title: 'Confira esta mídia',
            text: 'Estou compartilhando esta mídia com você!',
            files: [file],
          });
          console.log('Compartilhamento realizado com sucesso!');
        } catch (error) {
          console.error('Erro ao compartilhar:', error);
        }
      } else {
        alert('O compartilhamento não é suportado neste dispositivo.');
      }
    },
    async shareStats() {
      this.compartilhando = true;
      setTimeout(async () => {
        try {
          const statsSection = this.$refs.statsSection;
          const canvas = await html2canvas(statsSection);

          const blob = await new Promise((resolve) =>
            canvas.toBlob(resolve, 'image/png')
          );
          const file = new File([blob], 'estatisticas.png', {
            type: 'image/png',
          });

          if (navigator.share) {
            await navigator.share({
              title: 'Estatísticas do Jogador',
              text: 'Confira as estatísticas do jogador!',
              files: [file],
            });
            console.log('Estatísticas compartilhadas com sucesso!');
          } else {
            alert('Compartilhamento não suportado neste dispositivo.');
          }
        } catch (error) {
          console.error('Erro ao compartilhar as estatísticas:', error);
          alert('Não foi possível compartilhar as estatísticas.');
        } finally {
          this.compartilhando = false;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.game-stats {
  width: 100%;
  max-width: 600px; /* Limita a largura máxima */
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.share-mode {
  width: 1000px; /* Largura fixa para o conteúdo compartilhado */
  height: 1000px; /* Altura fixa para o conteúdo compartilhado */
  display: flex;
  flex-direction: column; /* Organiza os itens verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  justify-content: center; /* Centraliza verticalmente */
  background-color: #fff; /* Fundo branco para o print */
  margin: auto; /* Centraliza na página */
  border: 1px solid #ddd; /* Adiciona uma borda leve */
  border-radius: 8px; /* Cantos arredondados */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adiciona um leve sombreamento */
  padding: 20px; /* Espaçamento interno para o conteúdo */
  box-sizing: border-box; /* Inclui padding no tamanho total */
}

.share-mode h2 {
  margin-bottom: 20px; /* Espaço abaixo do título */
  font-size: 24px; /* Tamanho maior para o título */
  text-align: center; /* Centraliza o texto */
}

.share-mode .stat-time,
.share-mode .stat {
  width: 100%; /* Ocupa toda a largura disponível */
  margin-bottom: 15px; /* Espaçamento entre itens */
}

.share-mode .stat-time .time-container {
  flex-direction: column; /* Organiza os times em coluna */
}

.share-mode .stat-time .time-container img {
  width: 120px; /* Ajusta o tamanho das imagens */
  height: 120px;
  margin-bottom: 10px;
}

.share-mode .stat-time .time-nome {
  text-align: center; /* Centraliza o texto */
  font-size: 18px; /* Texto maior */
}

.share-mode .stat {
  display: flex;
  justify-content: space-between; /* Espaça os itens igualmente */
  align-items: center;
}

.share-mode .stat label {
  font-weight: bold; /* Destaca os rótulos */
}

.share-mode .space-betwen img {
  margin-top: 20px;
  max-width: 250px; /* Ajusta o tamanho da logo */
}

.share-mode .media-actions {
  display: none; /* Esconde ações de mídia no modo de compartilhamento */
}

.share-mode .img-responsive {
  max-width: 90%; /* Limita o tamanho da imagem */
  margin-bottom: 15px; /* Espaçamento inferior */
}

.stat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.stat-time {
  display: flex;
  justify-content: space-between; /* Mantém os times nas extremidades */
  align-items: center;
  gap: 20px;
  padding: 20px;
  text-align: center;
}

.time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.time-foto {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
}

.img-responsive {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.media-actions {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.space-betwen {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Mobile-first: container de times em coluna */
.cointainer-times {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.cointainer-time {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
}

.nome-time {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.row-dados-jogador {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}

.row-dados-jogador-margin {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.jogador-toto {
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
}

.jogador-nome {
  font-size: 16px;
  font-weight: bold;
  flex: 1;
  color: #444;
}

.jogador-posicao {
  font-size: 14px;
  color: #777;
  text-align: right;
}

/* Estilos para telas maiores */
@media (min-width: 768px) {
  .game-stats {
    max-width: 900px; /* Expande no desktop */
    padding: 30px; /* Mais espaço */
  }

  .stat-time {
    justify-content: space-evenly; /* Melhora o espaçamento no desktop */
  }

  .time-container {
    flex-direction: row; /* Organiza foto e nome lado a lado */
    align-items: center;
  }

  .time-foto {
    width: 100px; /* Aumenta as fotos no desktop */
    height: 100px;
  }

  .cointainer-times {
    flex-direction: row; /* Times lado a lado no desktop */
    gap: 40px;
  }

  .cointainer-time {
    width: 48%; /* Divide igualmente o espaço */
  }

  .row-dados-jogador {
    padding: 15px 0;
  }

  .jogador-nome {
    font-size: 18px;
  }

  .jogador-posicao {
    font-size: 16px;
  }

  .stat {
    justify-content: space-evenly; /* Melhor alinhamento para estatísticas */
  }
  .loading-overlay p {
    font-size: 18px; /* Texto maior no desktop */
  }
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-overlay p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}
</style>
