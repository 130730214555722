<template>
  <el-card class="jogador-card" shadow="hover">
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>
      <p>Carregando dados...</p>
    </div>
    <div class="jogador-info" v-else>
      <div
        ref="statsSection"
        :class="['stats-section', { 'share-mode': compartilhando }]"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <h4>{{ dados.partida.campo.nome }} {{ dataPartida }}</h4>
          </el-col>
          <el-col :span="12">
            <img
              v-if="dados.partida.time1.foto"
              :src="dados.partida.time1.foto"
              alt="Foto do time1"
              class="time-foto"
            />
            <img v-else :src="semLogo1" alt="Foto do time1" class="time-foto" />
            <h5>{{ dados.partida.time1.nome }}</h5>
          </el-col>
          <el-col :span="12">
            <img
              v-if="dados.partida.time2.foto"
              :src="dados.partida.time2.foto"
              alt="Foto do time2"
              class="time-foto"
            />
            <img v-else :src="semLogo2" alt="Foto do time2" class="time-foto" />
            <h5>{{ dados.partida.time2.nome }}</h5>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <img
              v-if="dados.jogador.foto"
              :src="dados.jogador.foto"
              alt="Foto do jogador"
              class="jogador-foto"
            />
            <img
              v-else
              :src="userFoto"
              alt="Foto do jogador"
              class="jogador-foto"
            />
            <h2>{{ dados.jogador.nome }}</h2>
            <p>{{ numero }} - {{ posicao }}</p>
            <p>{{ dados.time.nome }}</p>
          </el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Metros percorridos:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.distanciaPercorrida
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Chutes (alvo):</el-col>
          <el-col :span="12" class="stat-value">{{ dados.chutesAoGol }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Bolas recuperadas:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.bolasRecuperadas
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Passes recebidos:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.passesRecebidos
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Passes corretos:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.passesCertos
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Passes errados:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.passesErrados
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Assistências para gol:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.assistenciasAGols
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Gols:</el-col>
          <el-col :span="12" class="stat-value">{{ dados.gols }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Calorias Perdidas:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.caloriasPerdidas
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Score na partida:</el-col>
          <el-col :span="12" class="stat-value">{{ dados.score }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20" v-if="posicao === 'goleiro'">
          <el-col :span="12" class="stat-label">Defesas:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.defesasGoleiro
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20" v-show="compartilhando">
          <el-col :span="24" class="space-betwen">
            <img :src="logo" alt="logo aite" style="width: 250px" />
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24">
          <el-button type="success" icon="el-icon-share" @click="shareStats"
            >Compartilhar Estatísticas</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="midias">
            <h2>Mídias</h2>
            <div v-if="midias.length === 0">Nenhuma mídia disponível.</div>
            <div v-else>
              <el-row
                :gutter="20"
                v-for="(media, index) in midias"
                :key="index"
              >
                <el-col :span="24">
                  <template v-if="isImage(media.nomeArquivo)">
                    <img
                      :src="'data:image/png;base64,' + media.arquivo"
                      alt="Mídia"
                      class="media-image"
                    />
                  </template>
                  <template v-else-if="isVideo(media.nomeArquivo)">
                    <video controls class="media-video">
                      <source
                        :src="'data:video/mp4;base64,' + media.arquivo"
                        type="video/mp4"
                      />
                      Seu navegador não suporta o elemento de vídeo.
                    </video>
                  </template>
                  <template v-else>
                    <div>Formato de mídia não suportado.</div>
                  </template>
                  <div class="media-actions">
                    <el-button
                      type="primary"
                      size="mini"
                      icon="el-icon-download"
                      @click="downloadMedia(media)"
                      >Baixar</el-button
                    >
                    <el-button
                      type="success"
                      size="mini"
                      icon="el-icon-share"
                      @click="shareMedia(media)"
                      >Compartilhar</el-button
                    >
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import html2canvas from 'html2canvas';
import relatorioJogadorServices from '@/services/relatorioJogadorServices';
import userFoto from '@/assets/user.png';
import logo from '@/assets/logo.png';
import semLogo1 from '@/assets/sem-logo-1.png';
import semLogo2 from '@/assets/sem-logo-2.png';

export default {
  data() {
    return {
      id: null,
      dados: {},
      logo,
      semLogo1,
      semLogo2,
      compartilhando: false,
      userFoto: userFoto,
      isLoading: true,
      midias: [],
    };
  },
  computed: {
    numero() {
      return this.dados?.partida?.jogadoresPartida.find(
        (j) => j.jogadorId === this.$route.params.id
      )?.numero;
    },
    posicao() {
      return this.dados?.partida?.jogadoresPartida.find(
        (j) => j.jogadorId === this.$route.params.id
      )?.posicao;
    },
    dataPartida() {
      return this.dados?.partida
        ? this.formatarDataBrasil(this.dados?.partida.data) +
            ' ' +
            this.dados?.partida.horaInicio
        : '';
    },
  },
  async mounted() {
    try {
      this.dados = await relatorioJogadorServices.findById(
        this.$route.params.id,
        this.$route.params.idPartida
      );
      this.midias = await relatorioJogadorServices.findMidiasById(
        this.$route.params.id,
        this.$route.params.idPartida
      );
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    isImage(nomeArquivo) {
      const imageExtensions = ['png', 'jpg', 'jpeg', 'gif'];
      const fileExtension = nomeArquivo.split('.').pop().toLowerCase();
      return imageExtensions.includes(fileExtension);
    },
    formatarDataBrasil(dataIso) {
      if (!dataIso) return '';
      const [ano, mes, dia] = dataIso.split('-');
      return `${dia}/${mes}/${ano}`;
    },
    isVideo(nomeArquivo) {
      const videoExtensions = ['mp4', 'avi', 'mov'];
      const fileExtension = nomeArquivo.split('.').pop().toLowerCase();
      return videoExtensions.includes(fileExtension);
    },
    downloadMedia(media) {
      const link = document.createElement('a');
      const mimeType = this.isImage(media.nomeArquivo)
        ? 'image/png'
        : 'video/mp4';
      link.href = `data:${mimeType};base64,${media.arquivo}`;
      link.download = media.nomeArquivo;
      link.click();
    },
    async shareMedia(media) {
      if (navigator.share) {
        const mimeType = this.isImage(media.nomeArquivo)
          ? 'image/png'
          : 'video/mp4';
        const blob = await fetch(
          `data:${mimeType};base64,${media.arquivo}`
        ).then((res) => res.blob());
        const file = new File([blob], media.nomeArquivo, { type: mimeType });

        try {
          await navigator.share({
            title: 'Confira esta mídia',
            text: 'Estou compartilhando esta mídia com você!',
            files: [file],
          });
          console.log('Compartilhamento realizado com sucesso!');
        } catch (error) {
          console.error('Erro ao compartilhar:', error);
        }
      } else {
        alert('O compartilhamento não é suportado neste dispositivo.');
      }
    },
    async shareStats() {
      this.compartilhando = true;
      setTimeout(async () => {
        try {
          const statsSection = this.$refs.statsSection;
          const canvas = await html2canvas(statsSection);

          const blob = await new Promise((resolve) =>
            canvas.toBlob(resolve, 'image/png')
          );
          const file = new File([blob], 'estatisticas.png', {
            type: 'image/png',
          });

          if (navigator.share) {
            await navigator.share({
              title: 'Estatísticas do Jogador',
              text: 'Confira as estatísticas do jogador!',
              files: [file],
            });
            console.log('Estatísticas compartilhadas com sucesso!');
          } else {
            alert('Compartilhamento não suportado neste dispositivo.');
          }
        } catch (error) {
          console.error('Erro ao compartilhar as estatísticas:', error);
          alert('Não foi possível compartilhar as estatísticas.');
        } finally {
          this.compartilhando = false;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.jogador-card {
  max-width: 600px; /* Aumenta o tamanho máximo no desktop */
  margin: 0 auto;
  padding: 20px; /* Adiciona padding para melhor visualização */
}

.share-mode {
  width: 900px;
  height: 900px;
  display: flex;
  flex-direction: column; /* Empilha os itens verticalmente */
  justify-content: center; /* Centraliza verticalmente */
  background-color: #fff; /* Fundo branco para o print */
  margin: auto; /* Centraliza na página */
  border: 1px solid #ddd; /* Adiciona uma borda leve */
  border-radius: 8px; /* Cantos arredondados */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombreamento leve */
  overflow: hidden; /* Garante que o conteúdo não ultrapasse o limite */
  padding: 20px; /* Adiciona espaço interno */
  box-sizing: border-box; /* Inclui padding no cálculo do tamanho */
}

.stats-section img {
  max-width: 90%; /* Limita o tamanho das imagens */
  height: auto; /* Mantém a proporção */
  margin-bottom: 10px; /* Espaçamento entre as imagens */
}

.stats-section h4,
.stats-section h5,
.stats-section h2,
.stats-section p {
  margin: 5px 0; /* Adiciona espaço entre os textos */
  text-align: center; /* Centraliza o texto */
  word-wrap: break-word; /* Garante quebra de palavras longas */
}

.stats-section {
  padding: 20px; /* Adiciona espaço interno */
  margin: 10px; /* Adiciona espaço externo */
  background-color: #fff; /* Garante um fundo branco para o print */
  border-radius: 8px; /* Adiciona cantos arredondados */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adiciona um leve sombreamento */
}

.jogador-info {
  text-align: center;
}

/* Estatísticas */
.statistic-row {
  margin-bottom: 10px;
}

.stat-label {
  text-align: left;
  font-weight: bold;
}

.stat-value {
  text-align: right;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-overlay p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

/* Foto do Jogador */
.jogador-foto {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

/* Estilo das mídias */
.midias {
  margin-top: 20px;
}

.media-image,
.media-video {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.media-actions {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

/* Foto dos times */
.time-foto {
  width: 100px; /* Aumenta a foto no desktop */
  height: auto;
}

/* Ajuste no espaço entre itens */
.space-betwen {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.space-betwen img {
  display: block; /* Garante que a imagem esteja visível */
  margin: 10px auto;
  max-width: 100%; /* Ajusta a largura */
}

/* Estilos para telas maiores (desktop) */
@media (min-width: 768px) {
  .jogador-card {
    max-width: 900px; /* Expande o card no desktop */
    display: flex;
    flex-direction: column;
    padding: 30px; /* Mais espaço no desktop */
  }

  .jogador-info {
    text-align: left; /* Alinha o texto à esquerda no desktop */
  }

  .time-foto {
    width: 120px; /* Aumenta o tamanho da foto dos times */
  }

  .statistic-row {
    margin-bottom: 15px;
  }

  .stat-label {
    font-size: 16px;
  }

  .stat-value {
    font-size: 16px;
  }

  .midias {
    margin-top: 30px; /* Espaçamento maior para desktop */
  }

  .media-actions {
    justify-content: space-between; /* Melhora alinhamento no desktop */
  }

  .jogador-foto {
    width: 200px; /* Foto maior no desktop */
    height: 200px;
  }

  .loading-overlay p {
    font-size: 18px; /* Texto maior no desktop */
  }
}
</style>
