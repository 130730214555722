const axios = require('axios');

// Configuração padrão do Axios
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  Headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
});
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// interseptor se retornar 401 ou 403 limar dados de login do local storage
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      // localStorage.removeItem('token');
      // localStorage.removeItem('user');
      // localStorage.removeItem('role');
      // localStorage.removeItem('foto');
    }
    return Promise.reject(error);
  }
);
export default instance;
