import { render, staticRenderFns } from "./RelatorioJogador.vue?vue&type=template&id=28554f90&scoped=true"
import script from "./RelatorioJogador.vue?vue&type=script&lang=js"
export * from "./RelatorioJogador.vue?vue&type=script&lang=js"
import style0 from "./RelatorioJogador.vue?vue&type=style&index=0&id=28554f90&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28554f90",
  null
  
)

export default component.exports