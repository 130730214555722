<template>
    <div class="usuarios-container">
      <h1>Gerenciamento de Usuários</h1>
      <el-table :data="usuarios" border style="width: 100%">
        <el-table-column prop="nome" label="Nome" align="center"></el-table-column>
        <el-table-column prop="login" label="Email" align="center"></el-table-column>
        <el-table-column prop="role" label="Função Atual" align="center"></el-table-column>
        <el-table-column label="Ações" align="center" width="300">
          <template #default="scope">
            <el-select v-model="selectedRole[scope.row.id]" placeholder="Nova Role" size="mini">
              <el-option label="Administrador" value="ROLE_ADM"></el-option>
              <el-option label="Time" value="ROLE_TIME"></el-option>
              <el-option label="Partida" value="ROLE_PARTIDA"></el-option>
            </el-select>
            <el-button 
              type="primary" 
              size="mini" 
              :disabled="!selectedRole[scope.row.id]" 
              @click="atualizarRole(scope.row.id)">
              Atualizar Role
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="currentPage + 1"
        :page-size="pageSize"
        :total="totalUsuarios"
        layout="prev, pager, next"
        @current-change="carregarUsuarios"
      />
    </div>
  </template>
  
  <script>
  import autheticationService from '@/services/authService';
  
  export default {
    data() {
      return {
        usuarios: [], // Dados dos usuários
        totalUsuarios: 0, // Total de usuários
        pageSize: 10, // Quantidade de usuários por página
        currentPage: 0, // Página atual (base 0)
        selectedRole: {}, // Armazena a nova role selecionada para cada usuário
      };
    },
    methods: {
      async carregarUsuarios(page = 1) {
        try {
          const response = await autheticationService.usuarios(page - 1, this.pageSize); // Página começa em 0 no backend
          this.usuarios = response.content;
          this.totalUsuarios = response.totalElements;
          this.currentPage = page - 1;
        } catch (error) {
          console.error('Erro ao carregar usuários:', error);
        }
      },
      async atualizarRole(id) {
        try {
          const novaRole = this.selectedRole[id];
          await autheticationService.atualizarole(id, novaRole);
          this.$message({
            message: 'Role atualizada com sucesso!',
            type: 'success',
          });
          this.carregarUsuarios(this.currentPage + 1); // Recarrega a página atual
        } catch (error) {
          console.error('Erro ao atualizar role:', error);
          this.$message.error('Erro ao atualizar a role.');
        }
      },
    },
    mounted() {
      this.carregarUsuarios();
    },
  };
  </script>
  
  <style scoped>
  .usuarios-container {
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .el-table {
    margin-bottom: 20px;
  }
  
  .el-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  </style>
  