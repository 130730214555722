import router from '@/router';
import api from './api';

const campoServices ={
    listarTodos() {
        return api.get("/campos")
          .then(response => response.data)
          .catch(error => {
            if (error.response && error.response.status === 403){
              router.push("/login")
            }
            console.error('Erro ao listar campos:', error);
            throw error;
          });
      },
      buscarPorId(id) {
        return api.get(`/campos/${id}`)
          .then(response => response.data)
          .catch(error => {
            console.error(`Erro ao buscar campo com ID ${id}:`, error);
            throw error;
          });
      },
      salvar(campo) {
        return api.post("/campos", campo)
          .then(response => response.data)
          .catch(error => {
            console.error('Erro ao salvar campo:', error);
            throw error;
          });
      },
      atualizar(id, campo) {
        return api.put(`/campos/${id}`, campo)
          .then(response => response.data)
          .catch(error => {
            console.error(`Erro ao atualizar campo com ID ${id}:`, error);
            throw error;
          });
      },
      deletarPorId(id) {
        return api.delete(`/campos/${id}`)
          .then(response => response.data)
          .catch(error => {
            console.error(`Erro ao deletar campo com ID ${id}:`, error);
            throw error;
          });
      }
}
export default campoServices