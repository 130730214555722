import router from '@/router';
import api from './api';

const relatorioPartidaServices = {
  // Lista todas as dados-partida
  findAll() {
    return api
      .get('/dados-partida')
      .then((response) => response.data)
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          router.push('/login');
        }
        console.error('Erro ao listar dados-partida:', error);
        throw error;
      });
  },
  findByIdPartida(id) {
    return api
      .get(`${'/dados-partida'}/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(`Erro ao buscar partida com ID ${id}:`, error);
        throw error;
      });
  },
  findMidiasByIdPartida(id) {
    return api
      .get(`/dados-partida/${id}/midias`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(`Erro ao buscar partida com ID ${id}:`, error);
        throw error;
      });
  },
  save(partida) {
    return api
      .post('/dados-partida', partida)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Erro ao criar partida:', error);
        throw error;
      });
  },
  update(id, partida) {
    return api
      .put(`${'/dados-partida'}/${id}`, partida)
      .then((response) => response.data)
      .catch((error) => {
        console.error(`Erro ao atualizar partida com ID ${id}:`, error);
        throw error;
      });
  },
  deleteById(id) {
    return api
      .delete(`${'/dados-partida'}/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(`Erro ao deletar partida com ID ${id}:`, error);
        throw error;
      });
  },
};
export default relatorioPartidaServices;
