<template>
  <div class="container-form">
    <el-form ref="campoForm" :model="campo" :rules="rules" >
      <el-form-item label="Nome" prop="nome">
        <el-input v-model="campo.nome"></el-input>
      </el-form-item>
      <el-form-item label="País" prop="pais">
        <el-select v-model="campo.pais" placeholder="Selecione o país">
          <el-option label="Brasil" value="Brasil"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Estado" prop="estado">
        <el-input v-model="campo.estado" maxlength="2" placeholder="Ex: SP"></el-input>
      </el-form-item>
      <el-form-item label="Cidade" prop="cidade">
        <el-input v-model="campo.cidade"></el-input>
      </el-form-item>
      <el-form-item label="Rua" prop="rua">
        <el-input v-model="campo.rua"></el-input>
      </el-form-item>
      <el-form-item label="Bairro" prop="bairro">
        <el-input v-model="campo.bairro"></el-input>
      </el-form-item>
      <el-form-item label="Dono" prop="dono">
        <el-input v-model="campo.dono"></el-input>
      </el-form-item>
      <el-form-item label="Cpf/Cnpj" prop="cpfCnpj">
        <el-input v-mask="['###.###.###-##', '##.###.###/####-##']" v-model="campo.cpfCnpj" placeholder="Cpf/Cnpj" clearable></el-input>
      </el-form-item>
      <el-form-item label="Foto" prop="foto">
        <el-upload
          v-if="!campo.foto"
          :limit="1"
          class="upload-demo"
          :action="uploadAction"
          :headers="{ 'api-key': '3sd5f$%465a1gva56dfg@4w54654wt6w52y4w5245*&(*325y' }"
          :file-list="fileList"
          list-type="picture-card"
          :show-file-list="true"
          :auto-upload="true"
          accept="image/png,image/jpeg,image/jpg"
          :on-change="handleUpload"
          :before-upload="handleBeforeUpload"
          :on-remove="onRemove"
        >
          <span slot="tip" class="el-upload__tip">Tamanho máximo: 2MB</span>
          <i class="el-icon-plus"></i>
        </el-upload>
        <div v-else>
            <img :src="campo.foto" alt="Foto" style="width: 100px; height: 100px;">
            <el-button @click="handleRemove">
              <el-icon name="close" class="remove"></el-icon>
            </el-button>
          </div>
      </el-form-item>
    </el-form>
    <div class="buttons-container">
      <el-button type="primary" @click="onSubmit">Salvar</el-button>
      <el-button @click="cancelar">Cancelar</el-button>
    </div>
  </div>
</template>

<script>
import campoServices from '@/services/campoService';

export default {
  data() {
    return {
      uploadAction: process.env.VUE_APP_API_URL + '/files/upload',
      fileList: [],
      id: null,
      campo: {
        nome: '',
        rua: '',
        bairro: '',
        cidade: '',
        estado: '',
        pais: 'Brasil', // Default para Brasil
        dono: '',
        cpfCnpj: '',
        foto: null,
      },
      rules: {
        nome: [{ required: true, message: 'O nome é obrigatório', trigger: 'blur' }],
        pais: [{ required: true, message: 'O país é obrigatório', trigger: 'change' }],
        estado: [{ required: true, message: 'O estado é obrigatório', trigger: 'blur' }],
        cidade: [{ required: true, message: 'A cidade é obrigatória', trigger: 'blur' }],
        rua: [{ required: true, message: 'A rua é obrigatória', trigger: 'blur' }],
        bairro: [{ required: true, message: 'O bairro é obrigatório', trigger: 'blur' }],
        dono: [{ required: true, message: 'O dono é obrigatório', trigger: 'blur' }],
        foto: [{ required: true, message: 'A foto é obrigatória', trigger: 'change' }],
        cpfCnpj: [
          { required: true, message: 'Por favor, insira o CPF', trigger: 'blur' },
          { validator: this.validateCPF, trigger: 'blur' }
        ]
      },
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.campo = await campoServices.buscarPorId(this.id);
    }
  },
  methods: {
    handleRemove() {
      this.campo.foto = null;
    },
    handleUpload(file,raw) {
      this.campo.foto = raw[0].response;
    },
    validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

      if (cnpj.length !== 14 || /^(\d)\1{13}$/.test(cnpj)) {
        return false;
      }

      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
      }

      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      if (resultado !== parseInt(digitos.charAt(0))) {
        return false;
      }

      tamanho++;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      return resultado === parseInt(digitos.charAt(1));
    },
    validarCPF(cpf) {
      cpf = cpf.replace(/[^\d]+/g, '');

      if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false;
      }

      let soma = 0;
      let resto;

      for (let i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }

      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) {
        resto = 0;
      }

      if (resto !== parseInt(cpf.substring(9, 10))) {
        return false;
      }

      soma = 0;

      for (let i = 1; i <= 10; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i)
      }

      resto = (soma * 10) % 11

      if (resto === 10 || resto === 11) {
        resto = 0
      }

      return resto === parseInt(cpf.substring(10, 11))
    },
    validateCPFOrCNPJ (rule, value, callback) {
      if (!value) {
        return callback(new Error('CPF ou CNPJ é obrigatório'))
      }
      value = value.replace(/[^\d]+/g, '')
      if (value.length === 11) {
        if (!this.validarCPF(value)) {
          return callback(new Error('CPF inválido'))
        }
      } else if (value.length === 14) {
        if (!this.validarCNPJ(value)) {
          return callback(new Error('CNPJ inválido'))
        }
      } else {
        return callback(new Error('CPF ou CNPJ inválido'))
      }

      callback()
    },

    handleBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      const isImageFormat = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);

      if (!isImageFormat) {
        this.showNotification('Erro', 'O formato do arquivo deve ser PNG, JPG ou JPEG', 'error');
      }
      if (!isLt2M) {
        this.showNotification('Erro', 'O tamanho do arquivo não pode exceder 2MB', 'error');
      }

      return isLt2M && isImageFormat;
    },
    async onSubmit() {
      this.$refs.campoForm.validate(async (valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append('nome', this.campo.nome);
          formData.append('cidade', this.campo.cidade);
          formData.append('rua', this.campo.rua);
          formData.append('bairro', this.campo.bairro);
          formData.append('estado', this.campo.estado);
          formData.append('pais', this.campo.pais);
          formData.append('cpfCnpj', this.campo.cpfCnpj);
          formData.append('dono', this.campo.dono);
          if (this.campo.foto) {
            formData.append('foto', this.campo.foto);
          }
          formData.append('dataCriacao', new Date().toISOString().split('T')[0]);
          formData.append('horaCriacao', new Date().toTimeString().split(' ')[0]);

          if (this.id) {
            await campoServices.atualizar(this.id, formData);
          } else {
            await campoServices.salvar(formData);
          }
          this.$router.back();
        }
      });
    },
    cancelar() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.container-form {
  padding: 20px;
}

.upload-demo .el-icon-plus {
  font-size: 28px;
  color: #909399;
}

.upload-demo {
  width: 128px;
  height: 128px;
}

.el-button {
  margin-right: 10px;
}

.buttons-container {
  margin-top: 100px;
}
</style>
