<template>
    <div>
      <el-table :data="tableData" stripe>
        <el-table-column label="Partidas">
          <template slot-scope="scope">
            {{ scope.row.time1.nome }} x {{ scope.row.time2.nome }}
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template slot-scope="scope">
            <el-button @click="editItem(scope.row)" size="mini" type="primary"><i class="el-icon-edit"></i></el-button>
            <el-button @click="visualizar(scope.row)" size="mini" type="primary"><i class="el-icon-view"></i></el-button>
            <el-button @click="dados(scope.row)" size="mini" type="primary"><i class="el-icon-data-board"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <el-button class="add-button" @click="addItem" type="primary" icon="el-icon-plus"></el-button>
      <el-dialog :visible.sync="dialogVisible" title="Detalhes da Partida" width="90%">
        <el-form label-position="top" :model="partida" class="partida-form">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Campo">
                <el-input v-model="partida.campo.nome" placeholder="Campo" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Time 1">
                <el-input v-model="partida.time1.nome" placeholder="Time 1" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Time 2">
                <el-input v-model="partida.time2.nome" placeholder="Time 2" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
  
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Cor Time 1">
                <el-input v-model="partida.corTime1" placeholder="Cor Time 1" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Cor Time 2">
                <el-input v-model="partida.corTime2" placeholder="Cor Time 2" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Data da Partida">
                <el-input v-model="partida.data" placeholder="Data da Partida" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
  
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Hora de Início">
                <el-input v-model="partida.horaInicio" placeholder="Hora de Início" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Hora de Fim">
                <el-input v-model="partida.horaFim" placeholder="Hora de Fim" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="8" :lg="8">
              <el-form-item label="Administrador da Partida">
                <el-input v-model="partida.administradorPartida.nome" placeholder="Administrador" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
  
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="12" :lg="12">
              <el-form-item label="Data de Criação">
                <el-input v-model="partida.dataCriacao" placeholder="Data de Criação" disabled></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :xs="24" :sm="24" :md="12" :lg="12">
              <el-form-item label="Hora de Criação">
                <el-input v-model="partida.horaCriacao" placeholder="Hora de Criação" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
  
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Fechar</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
import partidaServices from '@/services/partidaService';


  export default {
    data() {
      return {
        dialogVisible: false,
        tableData: [
        ],
        partida: {
        campo: { nome: "Campo 1" },
        time1: { nome: "Time 1" },
        time2: { nome: "Time 2" },
        corTime1: "Azul",
        corTime2: "Vermelho",
        data: "2024-08-30",
        horaInicio: "14:00",
        horaFim: "16:00",
        administradorPartida: { nome: "Admin" },
        dataCriacao: "2024-08-27",
        horaCriacao: "10:30"
      }
          };
    },
    async mounted() {
      this.tableData = await partidaServices.findAll()
    },
    methods: {
      addItem() {
        this.$router.push("/novaPartida")
      },
      editItem(item) {
        this.$router.push("/editPartida/" + item.id)
      },
      visualizar(item) {
        this.partida = item
        this.dialogVisible = true
      },
      dados(item){
         this.$router.push("/dadosPartida/" + item.id)
      }
    },
  };
  </script>
  
  <style scoped>
  .add-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 15px;
    border-radius: 50%;
  }
  </style>
  