<template>
  <div class="login-container">
    <el-card class="login-card" shadow="hover">
      <h2 class="login-title">Email</h2>
      <el-form :model="loginForm" ref="loginForm" :rules="rules" label-width="0px">
        <el-form-item prop="login">
          <el-input v-model="loginForm.login" placeholder="email" clearable>
            <template slot="prepend"><i class="el-icon-user"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="loginForm.password" placeholder="Senha" clearable>
            <template slot="prepend"><i class="el-icon-lock"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login">Entrar</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="text" @click="openRegisterModal">Criar Usuário</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- Modal para criar usuário -->
    <el-dialog :visible.sync="showRegisterModal" title="Criar Usuário" width="90%">
      <el-form :model="registerForm" ref="registerForm" :rules="registerRules" label-width="0px">
        <el-form-item prop="login">
          <el-input v-model="registerForm.login" placeholder="Email" clearable type="email">
            <template slot="prepend"><i class="el-icon-user"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="registerForm.password" placeholder="Senha" clearable>
            <template slot="prepend"><i class="el-icon-lock"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input type="password" v-model="registerForm.confirmPassword" placeholder="Confirmar senha" clearable>
            <template slot="prepend"><i class="el-icon-lock"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="nome">
          <el-input v-model="registerForm.nome" placeholder="Nome" clearable>
            <template slot="prepend"><i class="el-icon-user-solid"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="cpf">
          <el-input v-mask="'###.###.###-##'" v-model="registerForm.cpf" placeholder="CPF" clearable>
          </el-input>
        </el-form-item>
        <el-form-item prop="datanascimento">
          <el-date-picker
            v-model="registerForm.dataNascimento"
            type="date"
            placeholder="Data de nascimento"
            :editable="false"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            @focus="openPicker"
          >
            <template slot="prepend"><i class="el-icon-date"></i></template>
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="peso">
          <el-input type="number" step="0.1" v-model="registerForm.peso" placeholder="Peso" clearable></el-input>
        </el-form-item>
        <el-form-item prop="altura">
          <el-input type="number" step="0.01" v-model="registerForm.altura" placeholder="Altura" clearable></el-input>
        </el-form-item>
        <el-form-item prop="sexo">
          <el-select v-model="registerForm.sexo" placeholder="Sexo">
            <el-option label="Masculino" value="M"></el-option>
            <el-option label="Feminino" value="F"></el-option>
            <el-option label="Outro" value="O"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="pais">
          <el-select v-model="registerForm.pais" placeholder="País">
            <el-option label="Brasil" value="Brasil"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="estado">
          <el-input v-model="registerForm.estado" placeholder="Estado" maxlength="2" clearable></el-input>
        </el-form-item>
        <el-form-item prop="cidade">
          <el-input v-model="registerForm.cidade" placeholder="Cidade" clearable></el-input>
        </el-form-item>
        <el-form-item prop="rua">
          <el-input v-model="registerForm.rua" placeholder="Rua" clearable></el-input>
        </el-form-item>
        <el-form-item prop="bairro">
          <el-input v-model="registerForm.bairro" placeholder="Bairro" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="registerForm.adm">Administrador de time</el-checkbox>
        </el-form-item>
        <el-form-item prop="foto">
          <el-upload
            v-if="!registerForm.foto"
            class="upload-demo"
            :action="uploadAction"
            :headers="{ 'api-key': '3sd5f$%465a1gva56dfg@4w54654wt6w52y4w5245*&(*325y' }"
            :on-change="handleFileChange"
            :before-upload="handleBeforeUpload"
            accept="image/png,image/jpeg,image/jpg"
            :limit="1"
            :auto-upload="true"
            list-type="picture-card"
          >
            <el-button type="primary">Selecionar Foto</el-button>
          </el-upload>
          <div v-else>
            <img :src="registerForm.foto" alt="Foto" style="width: 100px; height: 100px;">
            <el-button @click="onRemove">
              <el-icon name="close" class="remove"></el-icon>
            </el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="agreeToTerms">
            Concordo com os <a href="/#/politicas" target="_blank">termos de uso e políticas de privacidade</a>.
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :disabled="!agreeToTerms" @click="cadastrar">Registrar</el-button>
          <el-button @click="showRegisterModal = false">Cancelar</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import authService from '../services/authService.js';

export default {
  data() {
    return {
      agreeToTerms: false,
      pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
        },
      loginForm: {
        login: '',
        password: ''
      },
      registerForm: {
        adm:false,
        nome: '',
        cpf: '',
        dataNascimento: null,
        peso: null,
        altura: null,
        sexo: '',
        rua: '',
        bairro: '',
        cidade: '',
        estado: '',
        pais: '',
        foto: null, // Inicialmente nulo, será preenchido com o arquivo
        login: '',
        password: '',
        confirmPassword: ''
      },
      rules: {
        login: [
          { required: true, message: 'Por favor, insira o email válido', trigger: 'blur', type: 'email' }
        ],
        password: [
          { required: true, message: 'Por favor, insira a senha', trigger: 'blur' }
        ]
      },
      registerRules: {
        nome: [
          { required: true, message: 'Por favor, insira o seu nome', trigger: 'blur' }
        ],
        cpf: [
          { required: true, message: 'Por favor, insira o CPF', trigger: 'blur' },
          { validator: this.validateCPF, trigger: 'blur' }
        ],
        dataNascimento: [
          { required: true, message: 'Por favor, insira a data de nascimento', trigger: 'blur' }
        ],
        peso: [
          { required: true, message: 'Por favor, insira o peso', trigger: 'blur' }
        ],
        altura: [
          { required: true, message: 'Por favor, insira a altura', trigger: 'blur' }
        ],
        sexo: [
          { required: true, message: 'Por favor, selecione o sexo', trigger: 'change' }
        ],
        rua: [
          { required: true, message: 'Por favor, insira a rua', trigger: 'blur' }
        ],
        bairro: [
          { required: true, message: 'Por favor, insira o bairro', trigger: 'blur' }
        ],
        cidade: [
          { required: true, message: 'Por favor, insira a cidade', trigger: 'blur' }
        ],
        estado: [
          { required: true, message: 'Por favor, insira o estado', trigger: 'blur' }
        ],
        pais: [
          { required: true, message: 'Por favor, insira o país', trigger: 'blur' }
        ],
        foto: [
          { required: true, message: 'Por favor, selecione uma foto', trigger: 'change' }
        ],
        login: [
          { required: true, message: 'Por favor, insira um email válido', trigger: 'blur', type: 'email' }
        ],
        password: [
          { required: true, message: 'Por favor, insira a senha', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: 'Por favor, confirme sua senha', trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' }
        ]
      },
      showRegisterModal: false,
      uploadAction: process.env.VUE_APP_API_URL + '/files/upload'
    };
  },
  methods: {
    onRemove() {
      this.registerForm.foto = null;
    },
    validateCPF(rule, value, callback) {
      if (!value) {
        return callback(new Error('CPF é obrigatório'));
      }

      // Função de validação de CPF
      function validarCPF(cpf) {
        cpf = cpf.replace(/[^\d]+/g, '');

        if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
          return false;
        }

        let soma = 0;
        let resto;

        for (let i = 1; i <= 9; i++) {
          soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }

        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) {
          resto = 0;
        }

        if (resto !== parseInt(cpf.substring(9, 10))) {
          return false;
        }

        soma = 0;

        for (let i = 1; i <= 10; i++) {
          soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }

        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) {
          resto = 0;
        }

        return resto === parseInt(cpf.substring(10, 11));
      }

      if (!validarCPF(value)) {
        callback(new Error('CPF inválido'));
      } else {
        callback();
      }
    },
    openPicker() {
      this.$refs.datePicker.showPicker();
    },
    async login() {
      this.$refs.loginForm.validate(async (valid) => {
        if (!valid) {
          this.showNotification('Erro', 'Por favor, preencha todos os campos obrigatórios.', 'error');
          return;
        }
        try {
          await authService.login({
            login: this.loginForm.login,
            password: this.loginForm.password
          });
          this.$router.push("/home");
          window.location.reload();
        } catch (error) {
          this.showNotification('Erro', 'Ocorreu um erro ao tentar fazer login, verifique su usuario e senha.', 'error');
        }
      });
    },
    handleBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      const isImageFormat = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);

      if (!isImageFormat) {
        this.showNotification('Erro', 'O formato do arquivo deve ser PNG, JPG ou JPEG', 'error');
      }
      if (!isLt2M) {
        this.showNotification('Erro', 'O tamanho do arquivo não pode exceder 2MB', 'error');
      }

      return isLt2M && isImageFormat;
    },
    openRegisterModal() {
      this.showRegisterModal = true;
    },
    async cadastrar() {
      this.$refs.registerForm.validate(async (valid) => {
        if (!valid) {
          this.showNotification('Erro', 'Por favor, preencha todos os campos obrigatórios corretamente.', 'error');
          return;
        }
        try {
          const formData = new FormData();
          formData.append('adm', this.registerForm.adm);
          formData.append('login', this.registerForm.login);
          formData.append('password', this.registerForm.password);
          formData.append('nome', this.registerForm.nome);
          formData.append('cpf', this.registerForm.cpf);
          formData.append('dataNascimento', this.registerForm.dataNascimento);
          formData.append('peso', this.registerForm.peso);
          formData.append('altura', this.registerForm.altura);
          formData.append('sexo', this.registerForm.sexo);
          formData.append('rua', this.registerForm.rua);
          formData.append('bairro', this.registerForm.bairro);
          formData.append('cidade', this.registerForm.cidade);
          formData.append('estado', this.registerForm.estado);
          formData.append('pais', this.registerForm.pais);
          if (this.registerForm.foto) {
            formData.append('foto', this.registerForm.foto);
          }
          formData.append('dataCriacao', new Date().toISOString().split('T')[0]); // LocalDate
          formData.append('horaCriacao', new Date().toTimeString().split(' ')[0]); // LocalTime

          await authService.register(formData);
          this.showNotification('Sucesso', 'Usuário criado com sucesso!', 'success');
          this.registerForm = {
            adm:false,
            nome: '',
            cpf: '',
            dataNascimento: null,
            peso: null,
            altura: null,
            sexo: '',
            rua: '',
            bairro: '',
            cidade: '',
            estado: '',
            pais: '',
            foto: null,
            login: '',
            password: '',
            confirmPassword: ''
          };
          this.showRegisterModal = false;
        } catch (error) {
          if(error?.response?.status === 403) {
            this.showNotification('Erro ao registrar', 'Usuário já existe.', 'error');
            return;
          }
          alert(error?.message)
          if(error?.response?.status === 413) {
            this.showNotification('Erro ao registrar', 'Arquivo de imagem deve ter ate 5Mb.', 'alert');
            return;
          }
          this.showNotification('Erro ao registrar', 'Ocorreu um erro ao tentar criar o usuário.', 'error');
          console.log(error);
        }
      });
    },
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.registerForm.password) {
        callback(new Error('As senhas não correspondem'));
      } else {
        callback();
      }
    },
    handleFileChange(file,raw) {
      this.registerForm.foto = raw[0].response;
    },
    showNotification(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        duration: 3000
      });
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}
.login-card {
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
}
.login-title {
  text-align: center;
  margin-bottom: 20px;
}
.el-input__inner {
  font-size: 16px;
  padding: 12px 10px;
}
.el-input__placeholder {
  color: #a0a0a0;
}
.upload-demo i {
  margin-right: 8px;
}
</style>
