import api from './api';

const publicoService ={
      topTimes() {
        return api.get(`/publico/top/times`)
          .then(response => response.data)
          .catch(error => {
            console.error(`Erro ao atualizar campo com ID :`, error);
            throw error;
          });
      },
      topJogadores() {
        return api.get(`/publico/top/jogadores`)
          .then(response => response.data)
          .catch(error => {
            console.error(`Erro ao deletar campo com ID :`, error);
            throw error;
          });
      }
}
export default publicoService