import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import DadosUsuario from '../views/DadosUsuario.vue';
import DashBoard from '../views/DashBoard.vue';
import TelaLogin from '@/views/TelaLogin.vue';
import UsuariosListagem from '@/views/UsuariosListagem.vue';
import PoliticasDePrivacidade from '@/views/PoliticasDePrivacidade.vue';
import NovoTime from '@/views/time/NovoTime.vue';
import NovoCampo from '@/views/campo/novoCampo.vue';
import NovaPartida from '@/views/partida/NovaPartida.vue';
import ListaTimes from '@/views/time/listaTimes.vue';
import ListaCampos from '@/views/campo/listaCampos.vue';
import ListaPartidas from '@/views/partida/listaPartidas.vue';
import AdicionarJogador from '@/views/time/AdicionarJogador.vue';
import RelatorioJogador from '@/views/relatorios/RelatorioJogador.vue';
import RelatorioPartida from '@/views/relatorios/RelatorioPartida.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashBoard,
  },
  {
    path: '/politicas',
    name: 'politicas',
    component: PoliticasDePrivacidade,
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }, // Requer autenticação
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: UsuariosListagem,
    meta: { requiresAuth: true }, // Requer autenticação
  },
  {
    path: '/login',
    name: 'login',
    component: TelaLogin,
  },
  {
    path: '/novoTime',
    name: 'novoTime',
    component: NovoTime,
    meta: { requiresAuth: true }, // Requer autenticação
  },
  {
    path: '/editTime/:id',
    name: 'editTime',
    component: NovoTime,
    meta: { requiresAuth: true }, // Requer autenticação
  },
  {
    path: '/novoCampo',
    name: 'novoCampo',
    component: NovoCampo,
    meta: { requiresAuth: true }, // Requer autenticação
  },
  {
    path: '/editCampo/:id',
    name: 'editCampo',
    component: NovoCampo,
    meta: { requiresAuth: true }, // Requer autenticação
  },
  {
    path: '/novaPartida',
    name: 'novaPartida',
    component: NovaPartida,
    meta: { requiresAuth: true }, // Requer autenticação
  },
  {
    path: '/editPartida/:id',
    name: 'editPartida',
    component: NovaPartida,
    meta: { requiresAuth: true }, // Requer autenticação
  },
  {
    path: '/times',
    name: 'times',
    component: ListaTimes,
    meta: { requiresAuth: true }, // Requer autenticação
  },
  {
    path: '/campos',
    name: 'campos',
    component: ListaCampos,
    meta: { requiresAuth: true }, // Requer autenticação
  },
  {
    path: '/partidas',
    name: 'partidas',
    component: ListaPartidas,
    meta: { requiresAuth: true }, // Requer autenticação
  },
  {
    path: '/jogadores/:id',
    name: 'jogadores',
    component: AdicionarJogador,
    meta: { requiresAuth: true }, // Requer autenticação
  },
  {
    path: '/dadosJogador/:id/:idPartida',
    name: 'dadosJogador',
    component: RelatorioJogador,
  },
  {
    path: '/dadosPartida/:id',
    name: 'dadosPartida',
    component: RelatorioPartida,
  },
  {
    path: '/dadosUsuario',
    name: 'dadosUsuario',
    component: DadosUsuario,
    meta: { requiresAuth: true }, // Requer autenticação
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('user');

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
